import { createClient } from '@supabase/supabase-js'

import type { PostgrestSingleResponse } from '@supabase/supabase-js'

const clientUrl = 'https://rmwmliwjknofojvzuogs.supabase.co'
const supabase = createClient(
  clientUrl,
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJtd21saXdqa25vZm9qdnp1b2dzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDkwODM1MzksImV4cCI6MjAyNDY1OTUzOX0.FJCdziki3z7CVnDJoJulNd47cA-6IH8zaAds9rzg58M'
)

export interface IJDAnswer {
  so_id: string
  body: string
  title: string
  tags: string
  jdroid?: string
}

/**
 * Get the questions and answers from the database
 * @param start The start index of the range
 * @param end The end index of the range
 * @param isSSG check if ssg
 * @returns The response object containing the question and answer details
 */
const getQuestionsAndAnswers = async (
  start: number,
  end: number,
  isSSG: boolean = false
): Promise<PostgrestSingleResponse<any>> => {
  const select: string = isSSG ? 'so_id,title,body,tags,jdroid' : 'so_id,title,body,tags'
  return await supabase
    .from('jdroid')
    .select(select)
    .order('so_id', { ascending: false })
    .range(start, end)
}

/**
 * Get the total record count
 * @returns total record count
 */
const getCount = async (): Promise<any> => {
  return supabase.from('jdroid').select('*', { count: 'exact', head: true })
}

/**
 * @param questionNumber The question number
 * @returns The response object containing the question and answer details
 */
const getQuestionAndAnswer = async (
  questionNumber: number
): Promise<PostgrestSingleResponse<any>> => {
  return await supabase
    .from('jdroid')
    .select('so_id,title,body,tags,jdroid')
    .eq('so_id', questionNumber)
}

export default { getQuestionAndAnswer, getQuestionsAndAnswers, getCount }
