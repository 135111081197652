declare global {
  interface Window {
    google: any
  }
}
import { ACTION_SECTION } from '@/components/organisation/enum/organisation.enums'
import type { IOnboardingSave } from '@/components/organisation/interface/IOnboardingSteps'
import type { IActionButton } from '@/components/shared/PricingCard.vue'
import gtmService from '@/services/gtm.service'
import { EXISTING_PROJECT_SESSION } from '@/services/ide/projects.service'
import { getUserInvitation } from '@/services/teach.service'
import utilModelsService from '@/services/util.models.service'
import windowsClarityService from '@/services/windows.clarity.service'
import { useAuthStore } from '@/stores/auth.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import { useSubscribeStore } from '@/stores/subscribe.store'
import { ASSESSMENT_MODAL_HS, AUTHMODELHS } from '@/utils/models'
import { popupCenter } from '@/utils/window-popup'
import axios, { HttpStatusCode } from 'axios'
import { computed } from 'vue'
import { type Router } from 'vue-router'

export interface IinitAuthResponse {
  accountId: number
  google_client_id: string
  isUserLoggedIn: boolean
  kurukkuKuri: string
  robotChecked: boolean
  username?: string
  email?: string
  notificationCount?: number
  plan: string | null
  platformPlan: string | null
  profilePictureUrl: string | null
  environment?: string
  profilePictureId: string | null
  currentAccountId: number
  onboarded?: boolean
  firstName?: string
  lastName?: string
  currentOnboardingStep?: number
  occupation?: string
}

let router: Router | null = null

const invitations = computed(() => {
  return useOrganisationStore().userInvitations
})

/**
 * initiate router
 * @param vueRouter vue router
 */
const initServiceRouter = (vueRouter: Router) => {
  router = vueRouter
}

/**
 * @description This function is used to authenticate the user using social login
 * @param link The link to redirect to after login
 */
const loginThenRedirect = async (link: string) => {
  if (useAuthStore().isUserloggedIn) {
    useSubscribeStore().setRedirectTo(null)
    router?.push(link)
  } else {
    useAuthStore().setRedirectTo(link)
    utilModelsService.openModal(AUTHMODELHS.LOGIN)
  }
}
/**
 * this function runs every time the app is loaded
 */
const initGuestAuth = async () => {
  await axios
    .post('/api/doodle/init')
    .then((response: { data: IinitAuthResponse }) => {
      useAuthStore().setKurukkuKuri(response.data.kurukkuKuri)
      if (response.data.environment) {
        useAuthStore().setEnvironment(response.data.environment)
      }
      if (response.data.isUserLoggedIn || response.data.robotChecked) {
        useAuthStore().robotCheckDone()
      }
      useAuthStore().setAppInitiated(true)
    })
    .catch(() => {
      useAuthStore().onDisconnected()
    })
}
/**
 * this function runs every time the app is loaded
 * @param initLogging - if true, it will initialize the logging services
 * @param initiateOnboarding - if true, it will initialize the onboarding process
 * @param initiateInvitations = boolean
 */
const initAuth = async (
  initLogging: boolean = false,
  initiateOnboarding: boolean = true,
  initiateInvitations: boolean = true
) => {
  await axios
    .post('/api/doodle/init')
    .then(async (response: { data: IinitAuthResponse }) => {
      useAuthStore().setIsPremium(response.data?.platformPlan || null)
      useAuthStore().setGoogleClientId(response.data.google_client_id)
      useOrganisationStore().setActiveAccountId(-1)
      if (response.data.environment) {
        useAuthStore().setEnvironment(response.data.environment)
      }
      if (response.data.profilePictureUrl && response.data.profilePictureUrl != undefined) {
        useAuthStore().setprofilePictureUrl(response.data.profilePictureUrl)
      }
      if (!response.data.isUserLoggedIn) {
        googleOneTapSignin()
      }
      useAuthStore().initAuth(response.data)
      if (useAuthStore().isUserloggedIn) {
        useAuthStore().setOnboarded(!!response.data?.onboarded)
        useAuthStore().setCurrentOnboardingStep(response.data.currentOnboardingStep ?? null)

        if (initLogging) {
          gtmService.identifyGtm(response.data.email as string)
          windowsClarityService.identifyClarity(response.data.email as string)
        }
        if (invitations.value && invitations.value.length > 0) {
          useOrganisationStore().setUserInvitations([])
        }

        if (
          ((response.data?.currentOnboardingStep as number) == 0 ||
            (response.data?.currentOnboardingStep as number) == 2) &&
          !response.data?.onboarded &&
          initiateInvitations
        ) {
          await getUserInvitation()
        }

        if (!initiateOnboarding) {
          return
        } else if (
          ((response.data?.currentOnboardingStep as number) == 0 ||
            (response.data?.currentOnboardingStep as number) == 2) &&
          invitations.value &&
          invitations.value.length > 0
        ) {
          utilModelsService.openModal(AUTHMODELHS.INVITEACCEPT)
        } else if (
          !initiateInvitations ||
          (!response.data.onboarded && initLogging) ||
          (!initLogging &&
            (response.data?.currentOnboardingStep as number) < 2 &&
            !response.data?.onboarded)
        ) {
          const requestObj: IOnboardingSave = {
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            onboarded: false,
            step:
              (response.data.currentOnboardingStep == 0 ||
                response.data.currentOnboardingStep == 1) &&
              response.data.firstName &&
              response.data.firstName !== ''
                ? 2
                : response.data.currentOnboardingStep || 1,
            occupation: response.data.occupation
          }
          useAuthStore().setOnboardingSteps(requestObj)
          utilModelsService.closeModal(AUTHMODELHS.INVITEACCEPT)
          utilModelsService.openModal(AUTHMODELHS.ONBOARDING)
        } else {
          useAuthStore().setOnboardedCompleted(true)
        }
      }
    })
    .catch(() => {
      useAuthStore().onDisconnected()
    })
}

export enum SOCIAL_LOGIN_SERVICE {
  CLEVER = 'CLEVER',
  GOOGLE = 'Google',
  MICROSOFT = 'Microsoft',
  Github = 'Github'
}
/**
 * @description This function is used to authenticate the user using social login
 * @param service The social login service to use
 */
const socialAuthentication = async (service: SOCIAL_LOGIN_SERVICE) => {
  let url = ''
  switch (service) {
    case SOCIAL_LOGIN_SERVICE.CLEVER:
      url = '/api/oauth/authorization/clever?path='
      break
    case SOCIAL_LOGIN_SERVICE.GOOGLE:
      // url = '/api/oauth/google?path='
      url = '/api/oauth/authorization/google?path='
      break
    case SOCIAL_LOGIN_SERVICE.MICROSOFT:
      // url = '/api/oauth/ms?path='
      url = '/api/oauth/authorization/azure?path='
      break
    case SOCIAL_LOGIN_SERVICE.Github:
      url = '/api/oauth/authorization/github?path='
      break
    default:
      break
  }
  if (url) {
    if (useAuthStore().awaitRedirectTo) {
      const redirectTo: string = useAuthStore().redirectTo as string
      useAuthStore().setRedirectTo(null)
      await utilModelsService.closeModalAsync(AUTHMODELHS.LOGIN).then(async () => {
        const encodeURIComponent = window.encodeURIComponent(
          `${window.location.origin}${redirectTo}?social=true`
        )
        window.location.href = url + encodeURIComponent
        await initAuth(true)
      })
    } else if (useSubscribeStore().redirectTo) {
      const encodeURIComponent = window.encodeURIComponent(
        `${window.location.origin}${useSubscribeStore().redirectTo}?social=true`
      )
      window.location.href = url + encodeURIComponent
    } else {
      const encodeURIComponent = window.encodeURIComponent(window.location.href)
      //window.location.href = url + encodeURIComponent
      const popup = popupCenter({
        url: url + encodeURIComponent,
        target: 'popup',
        width: 600,
        height: 650
      })
      const timerID = setInterval(function () {
        try {
          if (popup && !popup.closed) {
            const popupURL = new URL(popup.location.href)
            const searchParams = new URLSearchParams(popupURL.search)
            if (searchParams.has('auth') && searchParams.get('auth') === 'success') {
              // console.log('Auth success 🟢 detected, popup closed.')
              clearInterval(timerID)
              popup.close()
              initAuth(true).then(() => {
                utilModelsService.closeModal(AUTHMODELHS.LOGIN)
              })
            }
          }
        } catch (error) {
          // Report Error
        } finally {
          if (popup?.closed) {
            clearInterval(timerID)
          }
        }
      }, 2000)
    }
  }
}

export interface IgoogleOneTapOptions {
  client_id: string
  auto_select: boolean
  cancel_on_tap_outside: boolean
  context: string
}

export interface IgoogleOneTapResponse {
  clientId: string
  client_id: string
  credential: string
  selected_by: string
}
/**
 * this function runs to suggest google one tap
 * @returns the googleOneTap function call
 */
const googleOneTapSignin = () => {
  const googleOptions: IgoogleOneTapOptions = {
    client_id: useAuthStore().googleClientId,
    auto_select: false, // optional
    cancel_on_tap_outside: false, // optional
    context: 'signin' // optional
  }

  /**
   * this function handles the response from the google one tap api
   * @param response from google
   */
  const handleOneTapResponse = (response: IgoogleOneTapResponse) => {
    //send token to backend
    const OneTapCredential: string = response.credential

    axios
      .post('/api/login/googleonetap', {
        credential: OneTapCredential
      })
      .then(async (response) => {
        if (response.status === HttpStatusCode.Ok) await initAuth(true)
      })
      .catch((error) => {
        throw error
      })
  }

  /**
   * this function runs to suggest google one tap
   * @param googleOptions options for googleoneTap
   * @param handleOneTapResponse callback to handle response from google one tap service
   */
  const googleOneTap = (googleOptions: IgoogleOneTapOptions, handleOneTapResponse: Function) => {
    const contextValue = ['signin', 'signup', 'use'].includes(googleOptions.context)
      ? googleOptions.context
      : 'signin'
    const googleScript = document.createElement('script')

    googleScript.setAttribute('src', 'https://accounts.google.com/gsi/client')
    document.head.appendChild(googleScript)
    /**
     * googleScript.onload instead of window.onload because window.onload can be triggered by other libraries and or just missed while googleScript.onload is more consistent
     */
    googleScript.onload = () => {
      if (googleOptions.client_id) {
        window.google.accounts.id.initialize({
          client_id: googleOptions.client_id,
          auto_select: googleOptions.auto_select,
          cancel_on_tap_outside: googleOptions.cancel_on_tap_outside,
          context: contextValue,
          callback: handleOneTapResponse
        })
        window.google.accounts.id.prompt()
      } else {
        // eslint-disable-next-line no-console
        console.error('client_id is missing')
      }
    }
  }
  return googleOneTap(googleOptions, handleOneTapResponse)
}

export interface IloginRequest {
  username: string
  password: string
  recaptcha: string
}
/**
 * @param loginRequest The login request object containing user credentials
 * @returns The response object containing the user details
 */
const login = async (loginRequest: IloginRequest) => {
  //@ts-ignore
  const env = process.env.NODE_ENV
  if (env === 'development') {
    try {
      const response = await axios.post('/api/login/authenticate', loginRequest, {
        headers: {
          'extension-client':
            router?.currentRoute.value.name == 'extensionLogin' ? 'chrome-ext' : ''
        }
      })
      if (response.data.error) throw response
      return response
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        return error.response
      }
      throw error // Throw other errors
    }
  } else {
    return await axios
      .post('/api/login/authenticate', loginRequest, {
        headers: {
          'extension-client':
            router?.currentRoute.value.name == 'extensionLogin' ? 'chrome-ext' : ''
        }
      })
      .then((response) => {
        if (response.data.error) throw response
        sessionStorage.removeItem(EXISTING_PROJECT_SESSION.EXISTING)
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}

export interface IRegisterRequest {
  username: string
  newPassword: string
  retypePassword: string
  recaptcha: string
}
/**
 * @param registerRequest The register request object containing user credentials
 * @returns The response object containing the user details
 */
const register = async (registerRequest: IRegisterRequest) => {
  return await axios
    .post('/api/register/register', registerRequest)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param verificationCode The verification code sent to the user's email
 * @description This function is used to verify the user's registration
 * @returns The response object containing the user details
 */
const verifyRegistration = async (verificationCode: string) => {
  if (useAuthStore().isUserloggedIn) {
    await logout()
    await initAuth()
  }
  return await axios
    .post('/api/register/verifyRegistration', {
      t: verificationCode
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export interface IForgotPasswordRequest {
  email: string
  gc: string
}
/**
 * @param forgotPasswordRequest The forgot password request object containing user credentials
 * @description This function is used to send the user a password reset link
 * @returns The response object containing the user details
 */
const forgotPassword = async (forgotPasswordRequest: IForgotPasswordRequest) => {
  return await axios
    .post('/api/register/forgotPassword', forgotPasswordRequest)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param verificationCode The verification code sent to the user's email
 * @description This function is used to verify the user's password reset token
 * @returns The response object containing the user details
 */
const verifyForgotPasswordToken = async (verificationCode: string) => {
  if (useAuthStore().isUserloggedIn) {
    await logout()
    await initAuth()
  }
  return await axios
    .post('/api/register/resetPasswordCodeCheck', {
      t: verificationCode
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export interface IRestPasswordRequest {
  username?: string
  otp?: string
  newPassword: string
  retypeNewPassword: string
  t?: string
}
/**
 * @param restPasswordRequest The reset password request object containing user credentials
 * @description This function is used to reset the user's password
 * @returns The response object containing the user details
 */
const resetForgotPassword = async (restPasswordRequest: IRestPasswordRequest) => {
  return await axios
    .post('/api/register/resetPassword', restPasswordRequest)
    .then((response) => {
      // useAuthStore().login(
      //   response.data.firstName,
      //   response.data.lastName,
      //   response.data.occupation,
      //   response.data.email
      // )
      return response
    })
    .catch((error) => {
      throw error
    })
}

export interface IChangePasswordRequest {
  oldPassword: string
  newPassword: string
  retypeNewPassword: string
}

/**
 * @description This function is used to change the user's password
 * @param changePasswordRequest  The change password request object containing user credentials
 * @returns The response object containing the user details
 */
const changePassword = async (changePasswordRequest: IChangePasswordRequest) => {
  return await axios
    .post('/api/register/changePassword', changePasswordRequest)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export interface IChangeUsernameRequest {
  firstName: string
  lastName: string
  occupation: string
}
/**
 * @description This function is used to change the user's username
 * @param changeUsernameRequest  The change username request object containing user credentials
 * @returns The response object containing the user details
 */
const updateUserDetails = async (changeUsernameRequest: IChangeUsernameRequest) => {
  return await axios
    .post('/api/register/updateDetails', changeUsernameRequest)
    .then((response) => {
      useAuthStore().login(
        changeUsernameRequest.firstName,
        changeUsernameRequest.lastName,
        changeUsernameRequest.occupation
      )
      return response
    })
    .catch((error) => {
      throw error
    })
}
/**
 * @description This function is used to clean users store values post logout
 */
const postLogout = () => {
  useAuthStore().logout()
  router?.push('/').then(() => {
    router?.go(0)
  })
}
/**
 * @description This function is used to log the user out
 * @returns The response object containing the user details
 */
const logout = async () => {
  return await axios
    .post('/api/logout/logoff')
    .then(async (response) => {
      sessionStorage.removeItem(EXISTING_PROJECT_SESSION.EXISTING)
      postLogout()
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @param data - customize the modal
 */
const OpenModelAfterLogin = (data: IActionButton) => {
  if (!useAuthStore().isUserloggedIn) {
    utilModelsService.openModal(AUTHMODELHS.LOGIN)
    useAuthStore().setOpenModalOnLogin(data.modalKey as string)
  }
  // If logged in -> Redirect
  if (!useOrganisationStore().activeAccountRoles) return
  if (useAuthStore().isUserloggedIn) {
    //assessment check
    if (useOrganisationStore().isIndividualAccount) {
      if (data.section == ACTION_SECTION.ASSESSMENTS) {
        utilModelsService.openModal(ASSESSMENT_MODAL_HS.SWITCH_TO_ORG)
        return
      }
    } else {
      router?.push(data.link as string)
    }
  }
}

/**
 * Handles the error by opening the OTP modal if the error message indicates that the email is unverified.
 * @param  error - The error code caught in a catch block.
 * @param  username - The username of the user whose email needs to be verified.
 */
const openOtpModelAgain = (error: string, username: string) => {
  if (error == 'Your email is unverified. Please verify it via OTP.') {
    useAuthStore().setUserEmailForOTPVerified(username)
    useAuthStore().setOtpRedirectFrom('Register')
    utilModelsService.closeModal(AUTHMODELHS.LOGIN)
    utilModelsService.openModal(AUTHMODELHS.OTP)
  }
}

/**
 * wait for app initiated because we want to check the environment
 * @param count count
 * @returns app initiated or not
 */
const postInitApp = async (count: number = 0): Promise<boolean> => {
  if (!useAuthStore().isInitiated) {
    if (count < 80) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return postInitApp(count + 1)
    } else {
      return false
    }
  } else return true
}

export default {
  initServiceRouter,
  loginThenRedirect,
  initGuestAuth,
  initAuth,
  socialAuthentication,
  login,
  register,
  verifyForgotPasswordToken,
  verifyRegistration,
  forgotPassword,
  resetForgotPassword,
  changePassword,
  updateUserDetails,
  logout,
  postLogout,
  googleOneTapSignin,
  OpenModelAfterLogin,
  openOtpModelAgain,
  postInitApp
}
