export enum LiveCodeActionsType {
  SYNC_PROJECT_TREE = 'SYNC_PROJECT_TREE',
  SYNC_INACTIVE_ITEMS = 'SYNC_INACTIVE_ITEMS',
  SYNC_UPLOAD_PROJECT_FILE = 'SYNC_UPLOAD_PROJECT_FILE',
  SYNCV_LIBRARY = 'SYNCV_LIBRARY',
  MAKE_HOME = 'MAKE_HOME',
  LIVE_WITH_EDIT = 'LIVE_WITH_EDIT',
  LIVE_WITH_VIEW = 'LIVE_WITH_VIEW',
  REMOVE_COLLAB = 'REMOVE_COLLAB',
  CALL_COLLAB = 'CALL_COLLAB'
}

export interface ILiveCodeActions {
  id?: string
  type: string
  data: any
  timestamp?: number
}

import { type mutex } from 'lib0/mutex.js'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Awareness } from 'y-protocols/awareness.js' // eslint-disable-line

export const useLiveCodeStore = defineStore('liveCode', () => {
  const awareness = ref<Awareness | null>(null)
  const mux = ref<mutex | null>(null)
  const collabId = ref<string | null>(null)
  const isProjectLoaded = ref<boolean>(false)
  const isLiveCodingActive = ref<boolean>(false)
  const isActionListnerInit = ref<boolean>(false)
  const isSyncing = ref<boolean>(false)
  const awaitLiveCodeEmit = ref<boolean>(false)

  /**
   * @param awr - awareness
   * @param mutx - mutex
   * @description - initialize the binding
   */
  const initBinding = (awr: Awareness, mutx: mutex) => {
    awareness.value = awr
    mux.value = mutx
  }
  /**
   * @description - disconnect the binding
   */
  const disconnectBinding = () => {
    isActionListnerInit.value = false
    awareness.value = null
    mux.value = null
  }
  /**
   * @param id - collab id
   * @description - setting the collab id
   */
  const setCollabId = (id: string) => {
    collabId.value = id
  }

  /**
   * @param value to update state
   * @description - setting the project loaded state
   */
  const setIsProjectLoaded = (value: boolean = false) => {
    isProjectLoaded.value = value
  }
  /**
   * opening the live coding modal
   * @param value to update state
   */
  const setIsLiveCodingActive = (value: boolean = false) => {
    isLiveCodingActive.value = value
  }
  /**
   * @param value to update state
   * @description - setting the action list
   */
  const setIsActionListnerInit = (value: boolean = false) => {
    isActionListnerInit.value = value
  }
  /**
   * @param value to update state
   * @description - setting the sync state
   */
  const setIsSyncing = (value: boolean = false) => {
    isSyncing.value = value
  }

  /**
   * @param value to update state
   * @description - setting the await live code emit state
   */
  const setAwaitLiveCodeEmit = (value: boolean = false) => {
    awaitLiveCodeEmit.value = value
  }
  return {
    awareness,
    mux,
    initBinding,
    disconnectBinding,
    collabId,
    setCollabId,
    isProjectLoaded,
    setIsProjectLoaded,
    isLiveCodingActive,
    setIsLiveCodingActive,
    isActionListnerInit,
    setIsActionListnerInit,
    isSyncing,
    setIsSyncing,
    awaitLiveCodeEmit,
    setAwaitLiveCodeEmit
  }
})
