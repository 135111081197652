/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {
  faFacebook,
  faGooglePlus,
  faHackerNews,
  faInstagram,
  faLinkedin,
  faPinterest,
  faReddit,
  faTelegram,
  faTumblr,
  faTwitter,
  faVk,
  faWhatsapp,
  faXTwitter,
  faXing,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDownZA,
  faArrowLeft,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowUpRightFromSquare,
  faArrowsLeftRight,
  faArrowsRotate,
  faArrowsTurnToDots,
  faArrowsUpDown,
  faBars,
  faBook,
  faBookmark,
  faBuilding,
  faBuildingColumns,
  faCalendar,
  faCamera,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleCheck,
  faCircleDollarToSlot,
  faCircleExclamation,
  faCircleInfo,
  faCircleNodes,
  faCircleQuestion,
  faCircleXmark,
  faClipboard,
  faClipboardList,
  faClock,
  faCloud,
  faCode,
  faComment,
  faCommentDots,
  faCompress,
  faCopy,
  faCube,
  faDisplay,
  faDollar,
  faDownload,
  faEarthAsia,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faExclamationCircle,
  faExpand,
  faEye,
  faFile,
  faFileAlt,
  faFileCirclePlus,
  faFileInvoiceDollar,
  faFileLines,
  faFilter,
  faFloppyDisk,
  faFolder,
  faFolderClosed,
  faFolderOpen,
  faFolderPlus,
  faFolderTree,
  faGear,
  faGears,
  faGlobe,
  faGraduationCap,
  faHand,
  faHandsClapping,
  faHandshakeAngle,
  faHeadset,
  faHeart,
  faHistory,
  faHome,
  faHouse,
  faIdCard,
  faKey,
  faKeyboard,
  faLanguage,
  faLaptopCode,
  faLayerGroup,
  faLightbulb,
  faLink,
  faListCheck,
  faLocationArrow,
  faLockOpen,
  faMagnifyingGlass,
  faMarker,
  faMaximize,
  faMessage,
  faMicrochip,
  faMinimize,
  faMobileScreenButton,
  faPalette,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPersonChalkboard,
  faPhone,
  faPlay,
  faPlus,
  faPrint,
  faPuzzlePiece,
  faQuestion,
  faQuestionCircle,
  faReceipt,
  faRectangleXmark,
  faRightFromBracket,
  faRobot,
  faRocket,
  faRotate,
  faRotateRight,
  faSchool,
  faScissors,
  faSearch,
  faShapes,
  faShareAltSquare,
  faShareFromSquare,
  faShareNodes,
  faShareSquare,
  faShieldHalved,
  faShuffle,
  faSpinner,
  faSquare,
  faStarHalfStroke,
  faStop,
  faTabletScreenButton,
  faTrash,
  faTrashCan,
  faTriangleExclamation,
  faUnlock,
  faUpDownLeftRight,
  faUpload,
  faUser,
  faUserGroup,
  faUserPlus,
  faUserTie,
  faUsers,
  faUsersBetweenLines,
  faVideo,
  faWallet,
  faXmark
} from '@fortawesome/free-solid-svg-icons'

import {
  faBell,
  faBookmark as faBookmarkRegular,
  faCalendar as faCalendarRegular,
  faClock as faClockRegular,
  faClone,
  faCreditCard,
  faEye as faEyeRegular,
  faEyeSlash,
  faFileCode,
  faFolder as faFolderRegular,
  faHand as faHandRegular,
  faPenToSquare as faPenToSquareRegular,
  faSquareCaretRight,
  faUser as faUserRegular
} from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(
  faEyeSlash,
  faEye,
  faArrowUpRightFromSquare,
  faChevronRight,
  faChevronDown,
  faSearch,
  faTrash,
  faCheck,
  faCircle,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowLeft,
  faMagnifyingGlass,
  faCircleInfo,
  faCircleXmark,
  faHeart,
  faPlay,
  faShuffle,
  faSpinner,
  faQuestion,
  faEnvelope,
  faFacebook,
  faTwitter,
  faXTwitter,
  faLinkedin,
  faPen,
  faUsers,
  faUserTie,
  faArrowDownZA,
  faGear,
  faCircleArrowLeft,
  faCircleArrowRight,
  faPenToSquare,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faMinimize,
  faMaximize,
  faUser,
  faUserPlus,
  faCaretRight,
  faCaretLeft,
  faCaretDown,
  faBars,
  faXmark,
  faHandshakeAngle,
  faArrowsRotate,
  faLocationArrow,
  faLayerGroup,
  faRectangleXmark,
  faLanguage,
  faKey,
  faIdCard,
  faRightFromBracket,
  faLaptopCode,
  faPuzzlePiece,
  faPersonChalkboard,
  faBuilding,
  faCheckDouble,
  faBuildingColumns,
  faBook,
  faLockOpen,
  faStarHalfStroke,
  faEarthAsia,
  faKeyboard,
  faListCheck,
  faShareNodes,
  faShareFromSquare,
  faUsersBetweenLines,
  faCode,
  faArrowsTurnToDots,
  faGraduationCap,
  faExclamationCircle,
  faGears,
  faCompress,
  faFolder,
  faTrashCan,
  faUpload,
  faStop,
  faCopy,
  faQuestionCircle,
  faHouse,
  faFolderOpen,
  faFolderTree,
  faHome,
  faCube,
  faFileAlt,
  faFileCirclePlus,
  faDownload,
  faPrint,
  faGooglePlus,
  faPinterest,
  faTumblr,

  faReddit,
  faXing,

  faYoutube,
  faWhatsapp,
  faHackerNews,
  faVk,
  faTelegram,
  faPlus,
  faFile,
  faFilter,
  faVideo,
  faFloppyDisk,
  faBookmark,
  faShareAltSquare,
  faShareSquare,
  faClipboard,
  faCircleQuestion,
  faHistory,
  faTriangleExclamation,
  faCircleCheck,
  faUnlock,
  faRotate,
  faRotateRight,
  faCircleExclamation,
  faFolderPlus,
  faFolderClosed,
  faEllipsis,
  faEllipsisVertical,
  faSquare,
  faExpand,
  faPaperPlane,
  faGlobe,
  faCalendar,
  faDollar,
  faCircleDollarToSlot,
  faRocket,
  faMicrochip,
  faSchool,
  faMarker,
  faFileLines,
  faCommentDots,
  faComment,
  faInstagram,
  faUpDownLeftRight,
  faScissors,
  faHand,
  faClock,
  faCloud,
  faMessage,
  faShieldHalved,
  faUserGroup,
  faLink,
  faWallet,
  faFileInvoiceDollar,
  faReceipt,
  faCircleNodes,
  faLightbulb,
  faArrowsUpDown,
  faArrowsLeftRight,
  faCamera,
  faHandsClapping,
  faRobot,
  faChevronLeft,

  faHeadset,
  faClipboardList,
  //Regular pack
  faEyeRegular,
  faPalette,
  faPhone,
  faShapes,
  faDisplay,
  faTabletScreenButton,
  faMobileScreenButton,

  //Regular pack
  faCreditCard,
  faFolderRegular,
  faSquareCaretRight,
  faBell,
  faUserRegular,
  faHandRegular,
  faBookmarkRegular,
  faCalendarRegular,
  faClockRegular,
  faSquareCaretRight,
  faPenToSquareRegular,
  faClone,
  faFileCode
)
