export const REQUIRMENPARAGRAPHSTYLE = {
  NORMAL: 'normal',
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  STRIKETHROUGH: 'strikethrough'
}
export const REQUIRMENTTYPE = {
  PARAGRAPH: 'paragraph',
  LINK: 'link'
}
interface IRequirment {
  style: string
  text: string
  link?: string
}
export interface ILibrary {
  manuallyInstall?: boolean
  package?: string
  library?: string
  header?: string
  validationRegex: RegExp | null
  placeholder: string
  requirments: Array<
    Array<{
      type: typeof REQUIRMENTTYPE.PARAGRAPH | typeof REQUIRMENTTYPE.LINK
      content: IRequirment
    }>
  >
  code?: string
}

export const LIBRARY: { [key: string]: ILibrary } = {
  maven: {
    package: 'Jar',
    library: 'Maven',
    validationRegex: /^([0-9a-zA-Z-.]+:[0-9a-zA-Z-.]+:[0-9a-zA-Z-.]+[:]?[0-9a-zA-Z-.]+]?)$/,
    placeholder: 'org.apache.commons:commons-lang3:3.4',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in public '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Maven '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search maven artifacts in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'http://search.maven.org/',
            link: 'http://search.maven.org/'
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'or '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'http://mvnrepository.com/',
            link: 'http://mvnrepository.com/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'It should be in a format like - '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'org.apache.commons:commons-lang3:3.4'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'On Maven, select "Gradle (short)" and copy the snippet. it will look something like this: '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: "implementation 'org.apache.commons:commons-lang3:3.4'"
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Remove "implementation" and the single quotes and you are left with the correct format:  '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'org.apache.commons:commons-lang3:3.4'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: "Add your library to the field above, and don't forget to import it in your code too"
          }
        }
      ]
    ]
  },
  nuget: {
    package: 'Library',
    library: 'Nuget',
    validationRegex: null,
    placeholder: 'Newtonsoft.Json',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in public '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Nuget '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artifacts in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://www.nuget.org/',
            link: 'https://www.nuget.org/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'It should be in a format like - '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Newtonsoft.Json:13.0.3'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Imagine you are using '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: '“using Newtonsoft.Json” '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'in your code to import this lib'
          }
        }
      ]
    ]
  },
  Arch: {
    package: 'Library',
    library: 'Arch Linux package',
    validationRegex: null,
    placeholder: 'pcre',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Arch Linux package '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the libraries or packages in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://archlinux.org/packages/',
            link: 'https://archlinux.org/packages/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Format should be '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'packagename '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'where package name is the name of the package as listed in the Arch Linux package repository'
          }
        }
      ]
    ]
  },

  ArchBash: {
    package: 'Library',
    library: 'Arch Linux package',
    validationRegex: null,
    placeholder: 'jq',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Arch Linux package '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the libraries or packages in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://archlinux.org/packages/',
            link: 'https://archlinux.org/packages/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Format should be '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'packagename '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'where package name is the name of the package as listed in the Arch Linux package repository'
          }
        }
      ]
    ]
  },
  pacman: {
    header: 'Add library from Arch Linux using pacman',
    validationRegex: null,
    placeholder: 'boost',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in public '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Arch Linux '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artefacts in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://archlinux.org/packages/',
            link: 'https://archlinux.org/packages/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Imagine you are using '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `#include <boost/algorithm/string.hpp> `
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'in your code'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Then you’ll need to install '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `pacman -S boost`
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'So you’ll need to add '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `"boost"`
          }
        }
      ]
    ]
  },
  pacman99: {
    header: 'Add library from Arch Linux using pacman',
    validationRegex: null,
    placeholder: 'gsl',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in public '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Arch Linux '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artefacts in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://archlinux.org/packages/',
            link: 'https://archlinux.org/packages/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Imagine you are using '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `#include <gsl/gsl_matrix.h> `
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'in your code'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Then you’ll need to install '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“pacman -S gsl”`
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'So you’ll need to add '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `"gsl"`
          }
        }
      ]
    ]
  },
  npm: {
    package: 'Library',
    library: 'NPM',
    validationRegex: null,
    placeholder: 'lodash:4.17.21',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in public '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'NPM '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artifacts in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://www.npmjs.com/',
            link: 'https://www.npmjs.com/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'It should be in a format like - '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'lodash:4.17.21'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Imagine you are using '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“const _ = require('lodash');” `
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'in your code to import this lib'
          }
        }
      ]
    ]
  },
  pip: {
    package: 'Library',
    library: 'pip',
    validationRegex: null,
    placeholder: 'pandas',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in public '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'pip '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artifacts in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://pypi.org/search/',
            link: 'https://pypi.org/search/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'It should be in a format like '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'pandas'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'The installation instructions will tell you to use terminal prompt like “pip2 install [libraryname]” or “pip install [libraryname]”'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: "Instead, just add “[libraryname]” to the field above, and don't forget to import it in your code too"
          }
        }
      ]
    ]
  },
  pip3: {
    package: 'Library',
    library: 'pip',
    validationRegex: null,
    placeholder: 'pandas',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in public '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'pip '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artifacts in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://pypi.org/search/',
            link: 'https://pypi.org/search/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'It should be in a format like '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'pandas'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'The installation instructions will tell you to use terminal prompt like “pip3 install [libraryname]” or “pip install [libraryname]”'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: "Instead, just add “[libraryname]” to the field above, and don't forget to import it in your code too"
          }
        }
      ]
    ]
  },
  composer: {
    header: 'Add library from the PHP package repository using Composer',
    validationRegex: null,
    placeholder: 'markrogoyski/math-php',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'packagist'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artifacts in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://packagist.org/',
            link: 'https://packagist.org/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Imagine you are using '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“markrogoyski/math-php:2.*”`
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Usually you’ll need to install it using '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“composer require markrogoyski/math-php:2.*”`
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'So you’ll need to add '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“require markrogoyski/math-php:2.*" `
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'or '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“markrogoyski/math-php"`
          }
        }
      ]
    ]
  },
  typenpm: {
    package: 'Library',
    library: 'NPM',
    validationRegex: null,
    placeholder: 'lodash:4.17.21',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in public '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'NPM '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artifacts in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://www.npmjs.com/',
            link: 'https://www.npmjs.com/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'It should be in a format like - '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: '@types/lodash:4.14.202'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Imagine you are using '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“const _ = require('lodash');” `
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'in your code to import this lib'
          }
        }
      ]
    ]
  },
  luaRocks: {
    header: 'Add library from The Lua package using Luarocks',
    validationRegex: null,
    placeholder: 'lua-zlib',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'LuaRocks'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artifacts in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://luarocks.org/',
            link: 'https://luarocks.org/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Imagine you are using '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“lua-zlib”`
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Usually you’ll need to install it using '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“luarocks install lua-zlib"`
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'So you’ll need to add '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“lua-zlib" `
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'or '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“lzlib"`
          }
        }
      ]
    ]
  },
  pub: {
    header: 'Add library from Pub Dev',
    validationRegex: null,
    placeholder: 'advance_math',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Pub Dev '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'package repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the libraries or packages in '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://pub.dev/',
            link: 'https://pub.dev/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Format should be '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: 'packagename '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'where package name is the name of the package as listed in the Pub Dev package repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Imagine you installed  '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: 'advance_math  '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'using '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“dart pub add advance_math", `
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'then you need to input '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `“advance_math"`
          }
        }
      ]
    ]
  },
  swift: {
    manuallyInstall: true,
    header:
      'In order to be able to use swift library, user has to be update Package.swift file in the project directory. ',
    validationRegex: null,
    placeholder: 'gsl',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'In order to be able to use swift library, user has to be update '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Package.swift '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'file in the project directory'
          }
        }
      ]
    ],
    code: `// swift-tools-version:5.5
import PackageDescription

let package = Package(
    name: "jdoodle",
    dependencies: [
        .package(url: "https://github.com/Maxim-Inv/SwiftDate.git", .branch("master"))
    ],
    targets: [
        .executableTarget(
            name: "jdoodle",
            dependencies: ["SwiftDate"],
            path: "."
        ),
    ]
)`
  },
  rubygems: {
    header: 'Add library from rubygems',
    validationRegex: null,
    placeholder: 'faker:2.18.0',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Library should be available in the public '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Rubygems '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artifacts at '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://rubygems.org/',
            link: 'https://rubygems.org/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Format should be like - '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'faker:2.18.0 '
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Imagine using  '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `"require 'faker' "  `
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: ' in your code to import this lib'
          }
        }
      ]
    ]
  },
  cpan: {
    header: 'Add library from cpan',
    validationRegex: null,
    placeholder: 'DateTime@1.65',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'The library should be available in the public '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Cpan '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artifacts at '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://metacpan.org/',
            link: 'https://metacpan.org/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'It should be in a format like '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'DateTime@1.65 '
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Imagine using  '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `"use DateTime; "  `
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: ' in your code to import this lib'
          }
        }
      ]
    ]
  },
  cran: {
    header: 'Add library from cran',
    validationRegex: null,
    placeholder: 'dplyr',
    requirments: [
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'The library should be available in the public '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'Cran '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'repository'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'You can search the artifacts at '
          }
        },
        {
          type: REQUIRMENTTYPE.LINK,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'https://cran.rstudio.com/',
            link: 'https://cran.rstudio.com/'
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'It should be in a format like '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.BOLD,
            text: 'dplyr '
          }
        }
      ],
      [
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: 'Imagine using  '
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.ITALIC,
            text: `"library(dplyr)"  `
          }
        },
        {
          type: REQUIRMENTTYPE.PARAGRAPH,
          content: {
            style: REQUIRMENPARAGRAPHSTYLE.NORMAL,
            text: ' in your R script '
          }
        }
      ]
    ]
  }
}
