declare global {
  interface Window {
    HSOverlay: any
    HSStaticMethods: any
    $hsOverlayCollection: [] | any
  }
}

import { useUtilModelsStore } from '@/stores/util.models.store'

/**
 * This function overwrite modal accessibility static method
 */
const preventOverlayAccessibility = () => {
  if (window && window.HSStaticMethods && window.HSStaticMethods.autoInit) {
    const original = window.HSStaticMethods.autoInit
    /**
     * @param collection preline comp to auto init
     */
    window.HSStaticMethods.autoInit = (collection: string | string[] = 'all') => {
      if (collection === 'all' || collection === 'overlay' || collection?.includes('overlay')) {
        if (window.HSOverlay && window.HSOverlay.accessibility) {
          /**
           *  overwrite accessibility
           */
          window.HSOverlay.accessibility = () => {}
          /**
           * This logic adds negative tab index to google recaptcha iframes to ignore inside links
           */
          const iframes = document.querySelectorAll('iframe[title="reCAPTCHA"]')
          iframes.forEach((iframe) => {
            if (iframe.getAttribute('tabindex') !== '-1') {
              iframe.setAttribute('tabindex', '-1')
            }
          })
        }
      }
      original(collection)
    }
  }
}

/**
 * Asynchronously waits for the `HSOverlay` object to be available on the `window`.
 * @param count - The current retry count. Defaults to 0.
 * @returns  - A promise that resolves to `true` if `HSOverlay` is available, otherwise `false`.
 */
const postHsModel = async (count: number = 0): Promise<boolean> => {
  if (!window?.HSOverlay) {
    if (count < 20) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      return await postHsModel(count + 1)
    }
    return false
  } else {
    try {
      window?.HSStaticMethods.autoInit()
    } catch (error) {
      ;() => {}
    }

    return true
  }
}

/**
 * Create a promise to confirm the action
 * @param title title of the confirm dialog
 * @param subtitle subtitle of the confirm dialog
 * @param retunHs return hash to return to after confirm
 * @param yesBtnText yes button text
 * @param noBtnText  no button text
 * @param hideCloseBtn conditionally hide the close button
 * @param yesBtnClass yes button style class
 * @param noBtnClass no button style class
 * @returns promise to confirm the action
 */
const confirmPromise = (
  title: string = 'Are you sure?',
  subtitle: string | null = null,
  retunHs: string | null = null,
  yesBtnText: string = 'Yes',
  noBtnText: string = 'No',
  hideCloseBtn: boolean = false,
  yesBtnClass: string | null = null,
  noBtnClass: string | null = null
): Promise<boolean> => {
  return useUtilModelsStore().setconfrimDetails(
    title,
    subtitle,
    retunHs,
    yesBtnText,
    noBtnText,
    hideCloseBtn,
    yesBtnClass,
    noBtnClass
  )
}
/**
 * on confirm click
 */
const confirmeResolve = () => {
  useUtilModelsStore().setconfrimDetailsResolve()
}
/**
 * on confirm click
 */
const confirmeReject = () => {
  useUtilModelsStore().setconfrimDetailsReject()
}

/**
 * Create a time out alert
 * @param title title of the alert dialog
 * @param subtitle subtitle of the alert dialog
 * @param retunHs return hash to return to after alert
 */
const alertTimeOut = (
  title: string = 'Alert',
  subtitle: string | null = null,
  retunHs: string | null = null
) => {
  useUtilModelsStore().setAlertDetails(title, subtitle, retunHs)
}
/**
 * Create a Info dialog
 * @param title title of the Info dialog
 * @param subtitle subtitle of the Info dialog
 */
const showInfo = (title: string = 'Info', subtitle: string | null = null) => {
  useUtilModelsStore().setInfoDetails(true, title, subtitle)
}
/**
 * hide the Info dialog
 */
const hideInfo = () => {
  useUtilModelsStore().setInfoDetails(false, '', null)
}
/**
 * open the model
 * @param hs hash to close
 */
const openModal = async (hs: string) => {
  await postHsModel()
  const htmlHs: HTMLElement | null = document.getElementById(hs)
  if (htmlHs) {
    window?.HSOverlay?.open(htmlHs)

    if (htmlHs.classList.contains('hidden')) {
      htmlHs.classList.remove('hidden')
      htmlHs.classList.add('open')
      htmlHs.classList.add('opened')
    }
  }
}
/**
 * close the model
 * @param hs hash to close
 * @returns promise to close the model
 */
const closeModalAsync = (hs: string): Promise<boolean> => {
  const promise: Promise<boolean> = new Promise((resolve) => {
    // Add Eventlistener to wait until all the overlays have been closed,
    // unless a fullscreen mask still on after redirect.
    const htmlBackdrop: HTMLElement | null = document.querySelector(
      '[data-hs-overlay-backdrop-template]'
    )
    if (htmlBackdrop) {
      document
        .querySelector('[data-hs-overlay-backdrop-template]')
        ?.addEventListener('transitionend', () => {
          resolve(true)
        })
    } else {
      // Add Eventlistener to wait until all the overlays have been closed,
      // unless a fullscreen mask still on after redirect.
      window.addEventListener('close.hs.overlay', async ($overlayEl) => {
        const element = $overlayEl.target as HTMLElement

        //if element has and data-hs-overlay
        if (element.getAttribute('data-hs-overlay')) {
          // remove # from the id
          const id = element.getAttribute('data-hs-overlay')?.replace('#', '') || ''
          if (id === hs) {
            resolve(true)
            return
          }
        }

        const ancestor = element.closest('.hs-overlay')
        if (!ancestor) return
        const id = ancestor.getAttribute('id') || ''
        if (id === hs) {
          resolve(true)
        }
      })
    }
  })
  closeModal(hs)
  return promise
}
/**
 * close the model
 * @param hs hash to close
 */
const closeModal = async (hs: string) => {
  const hsId = `#${hs}`
  await postHsModel()
  const element: HTMLElement | null = document.querySelector(hsId)
  if (!element) return
  const elInCollection = window?.$hsOverlayCollection?.findIndex((el: any) => {
    return el.element.overlayId === hsId
  })

  if (elInCollection && elInCollection === -1 && window && window.HSOverlay) {
    const hsElement = new window.HSOverlay(element)

    window?.$hsOverlayCollection?.push({
      id: hsElement?.el?.id || window?.$hsOverlayCollection?.length + 1,
      element: hsElement
    })

    if (!hsElement.overlay) {
      hsElement.overlay = element
      hsElement.overlayId = hsId
    }
    await hsElement?.close(true)
  } else {
    if (window?.$hsOverlayCollection) {
      const overlaysToClose = window.$hsOverlayCollection.filter(
        (overlay: any) => overlay?.element?.overlayId === hsId
      )
      for (const overlay of overlaysToClose) {
        overlay?.element?.close(true)
      }
    }
  }
}
/**
 * on close model
 * @param callback callback to run on close model
 */
const onCloseModel = async (callback: (instance: string | null) => void) => {
  window.addEventListener('close.hs.overlay', async ($overlayEl) => {
    const element = $overlayEl.target as HTMLElement
    //if element has and data-hs-overlay
    if (element.getAttribute('data-hs-overlay')) {
      // remove # from the id
      const id = element.getAttribute('data-hs-overlay')?.replace('#', '') || ''
      callback(id)
      return
    }

    const ancestor = element.closest('.hs-overlay')
    if (!ancestor) return
    const id = ancestor.getAttribute('id') || ''
    callback(id)
  })
}
/**
 * on close model
 * @param callback callback to run on close model
 */
const onOpenModel = async (callback: (instance: string | null) => void) => {
  window.addEventListener('open.hs.overlay', async ($overlayEl) => {
    const element = $overlayEl.target as HTMLElement

    //if element has and data-hs-overlay
    if (element.getAttribute('data-hs-overlay')) {
      // remove # from the id
      const id = element.getAttribute('data-hs-overlay')?.replace('#', '') || ''
      callback(id)
      return
    }

    const ancestor = element.closest('.hs-overlay')
    if (!ancestor) return
    const id = ancestor.getAttribute('id') || ''
    callback(id)
  })
}
export default {
  openModal,
  closeModalAsync,
  closeModal,
  confirmPromise,
  confirmeResolve,
  confirmeReject,
  alertTimeOut,
  showInfo,
  hideInfo,
  onCloseModel,
  onOpenModel,
  preventOverlayAccessibility
}
