import {
  CURRENT_RUNNING_WS_SERVICE,
  INIT_NEXT_ACTION
} from '@/components/organisation/enum/advanceCompiler.enums'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useAdvanceCompilerStore = defineStore('advanceCompiler', () => {
  const isPodInitializationCompleted = ref<boolean>(false)
  const logMessages = ref<string[]>([])
  const startLoggingMessages = ref<boolean>(false)
  const serverErrorsMessages = ref<string | null>(null)
  const socketConnected = ref<boolean>(false)
  const socketCompleted = ref<boolean>(false)
  const isPodInitializationInProgress = ref<boolean>(false)
  const subDomainUrl = ref<string | null>(null)
  const isPodInitializationHasErrors = ref<boolean>(false)
  const sessionId = ref<string | null>(null)
  const kurukkuKuriId = ref<string | null>(null)
  const projectKeyAdvCompiler = ref<number | null>(null)
  const projectIdAdvCompiler = ref<number | null>(null)
  const currentWsServiceAdvCompiler = ref<string | null>(null)
  const startPodsCompleted = ref<boolean | null>(false)
  const stopPodsCompleted = ref<boolean | null>(false)
  const installLibrariesInitiated = ref<boolean | null>(false)
  const installLibrariesCompleted = ref<boolean | null>(false)
  const startPodsInitiated = ref<boolean | null>(false)
  const stopPodsInitiated = ref<boolean | null>(false)
  const loadingPods = ref<boolean>(false)
  const initNextAction = ref<INIT_NEXT_ACTION | null>(null)
  const socketInProgress = computed(() => {
    if (
      currentWsServiceAdvCompiler.value === CURRENT_RUNNING_WS_SERVICE.INIT &&
      isPodInitializationCompleted.value == false
    )
      return true
    else if (loadingPods.value) return true
    else if (stopPodsInitiated.value && stopPodsCompleted.value == false) return true
    else if (installLibrariesInitiated.value && installLibrariesCompleted.value == false)
      return true
    else if (socketCompleted.value) return false
    else return false
  })

  const socketLogsInProgress = computed(() => {
    if (socketInProgress.value) {
      if (logMessages.value.length > 0) {
        return true
      }
      return false
    }
    return false
  })

  /**
   * Sets the state of pod initialization completion.
   * @param val - The value to set for pod initialization completion.
   */
  const setIsPodInitializationCompleted = (val: boolean) => {
    isPodInitializationCompleted.value = val
  }
  /**
   * Updates the log messages based on the provided input.
   *
   * If the input is an array, this function resets the `logMessages` array.
   * If the input is a string, it appends the message to the existing array of
   * log messages.
   * @param message - The log message(s) to set. Can be a
   *   single message string or an array of messages.
   * @param replaceLastLine - Indicates whether to replace the last message
   */
  const setLogMessages = (message: string | string[], replaceLastLine: boolean = false) => {
    if (Array.isArray(message)) {
      logMessages.value = [] // Reset the logMessages array
    } else {
      if (replaceLastLine && logMessages.value.length > 0) {
        logMessages.value[logMessages.value.length - 1] = message
      } else {
        logMessages.value = [...logMessages.value, message] // Add the log message to the array
      }
    }
  }
  /**
   * Sets the state for starting or stopping log message recording.
   *
   * This function updates the `startLoggingMessages` reactive value to
   * indicate whether logging of messages should begin or stop.
   * @param val - The value to set for starting or stopping logging.
   */
  const setStartLoggingMessages = (val: boolean) => {
    startLoggingMessages.value = val
  }
  /**
   * Sets the current server error message.
   *
   * This function updates the `serverErrorsMessages` reactive value with
   * the provided error message. If the value is `null`, it indicates that
   * there is no current error message.
   * @param val - The current error message, or `null` if
   * there is no error.
   */
  const setServerErrorMessages = (val: string | null) => {
    serverErrorsMessages.value = val
  }
  /**
   * Sets the connection state of the socket.
   *
   * This function updates the `socketConnected` reactive value to indicate
   * whether the socket connection is currently established or not.
   * @param val - The current connection state of the socket.
   * `true` if connected, `false` if disconnected.
   */
  const setSocketConnected = (val: boolean) => {
    socketConnected.value = val
  }
  /**
   * Sets the completion state of the socket connection.
   *
   * This function updates the `socketCompleted` reactive value to indicate
   * whether the socket connection is currently completed or not.
   * @param val - The current completion state of the socket.
   * `true` if completed, `false` if not.
   */
  const setSocketCompleted = (val: boolean) => {
    socketCompleted.value = val
  }
  /**
   * Sets the state of pod initialization progress.
   *
   * This function updates the `isPodInitializationInProgress` reactive value
   * to indicate whether the pod initialization is currently in progress.
   * @param val - The current state of pod initialization.
   * `true` if initialization is in progress, `false` otherwise.
   */
  const setIsPodInitializationInProgress = (val: boolean) => {
    isPodInitializationInProgress.value = val
  }
  /**
   * Sets the subdomain URL based on the provided value.
   *
   * If a valid subdomain string is provided, this function constructs a URL
   * using the current hostname. If the current hostname is `localhost`, it
   * substitutes it with `dev.jdoodle.com`. If the value is `null`, it resets
   * the `subDomainUrl` to `null`.
   * @param val - The subdomain to set, or `null` to reset the URL.
   */
  const setSubDomainUrl = (val: string | null) => {
    if (val) {
      if (process.env.NODE_ENV === 'development') {
        const proxyEnv = (import.meta.env.VITE_PROXY_BACKEND_URL as string) || undefined
        const extractedPortEnv = proxyEnv?.replace('https://', '').replace(/\/$/, '') || ''
        subDomainUrl.value = `https://${val}.advcompiler-${extractedPortEnv}/`
      } else {
        subDomainUrl.value =
          window.location.hostname === 'www.jdoodle.com'
            ? `https://${val}.advcompiler.jdoodle.com/`
            : `https://${val}.advcompiler-${window.location.hostname}/`
      }
    } else {
      subDomainUrl.value = null
    }
  }
  /**
   * Sets the error state of pod initialization.
   *
   * This function updates the `isPodInitializationHasErrors` reactive value
   * to indicate whether there are errors during the pod initialization process.
   * @param val - The current error state of pod initialization.
   * `true` if there are errors, `false` otherwise.
   */
  const setIsPodInitializationHasErrors = (val: boolean) => {
    isPodInitializationHasErrors.value = val
  }
  /**
   * Sets the current session ID.
   *
   * This function updates the `sessionId` reactive value with the provided
   * session ID. If the value is `null`, it indicates that there is no current
   * session ID.
   * @param val - The session ID to set, or `null` if there
   * is no session ID.
   */
  const setSessionId = (val: string | null) => {
    sessionId.value = val
  }

  /**
   * Sets the current kurukku Kuri ID.
   *
   * This function updates the `kurukkuKuriId` reactive value with the
   * provided Kuri ID. If the value is `null`, it indicates that there is
   * no current Kuri ID.
   * @param val - The Kuri ID to set, or `null` if there
   * is no Kuri ID.
   */
  const setkurukkuKuriId = (val: string | null) => {
    kurukkuKuriId.value = val
  }
  /**
   * Sets the project key for the advanced compiler.
   *
   * This function updates the `projectKeyAdvCompiler` reactive value with
   * the provided project key. If the value is `null`, it indicates that
   * there is no current project key.
   * @param val - The project key to set, or `null` if there
   * is no project key.
   */
  const setProjectKeyAdvCompiler = (val: number | null) => {
    projectKeyAdvCompiler.value = val
  }

  /**
   * Sets the project ID for the advanced compiler.
   *
   * This function updates the `projectIdAdvCompiler` reactive value with
   * the provided project ID. If the value is `null`, it indicates that
   * there is no current project ID.
   * @param  val - The project ID to set, or `null` if there
   * is no project ID.
   */
  const setProjectIdAdvCompiler = (val: number | null) => {
    projectIdAdvCompiler.value = val
  }
  /**
   * Sets the current WebSocket service for the advanced compiler.
   *
   * This function updates the `currentWsServiceAdvCompiler` reactive value
   * with the provided WebSocket service identifier. If the value is `null`,
   * it indicates that there is no current WebSocket service.
   * @param val - The current WebSocket
   * service identifier to set, or `null` if there is no active service.
   */
  const setCurrentWsServiceAdvCompiler = (val: CURRENT_RUNNING_WS_SERVICE | null) => {
    currentWsServiceAdvCompiler.value = val
  }

  /**
   * Sets the completion state of starting pods.
   *
   * This function updates the `startPodsCompleted` reactive value with the
   * provided state. If the value is `null`, it indicates that the completion
   * state is unknown.
   * @param  val - The completion state to set.
   * `true` if starting pods is completed, `false` if not, or `null`
   * if the state is unknown.
   */
  const setStartPodsCompleted = (val: boolean | null) => {
    startPodsCompleted.value = val
  }
  /**
   * Sets the completion state of stopping pods.
   *
   * This function updates the `stopPodsCompleted` reactive value with the
   * provided state. If the value is `null`, it indicates that the completion
   * state is unknown.
   * @param val - The completion state to set.
   *   `true` if stopping pods is completed, `false` if not, or `null`
   *   if the state is unknown.
   */
  const setStopPodsCompleted = (val: boolean | null) => {
    stopPodsCompleted.value = val
  }
  /**
   * Sets the completion state of installing pods.
   *
   * This function updates the `installLibrariesCompleted` reactive value
   * with the provided state. If the value is `null`, it indicates that the
   * completion state is unknown.
   * @param val - The completion state to set.
   *   `true` if installing pods is completed, `false` if not, or `null`
   *   if the state is unknown.
   */
  const setInstallLibrariesCompleted = (val: boolean | null) => {
    installLibrariesCompleted.value = val
  }
  /**
   * Sets the initiation state of installing libraries.
   *
   * This function updates the `installLibrariesInitiated` reactive value with
   * the provided state. If the value is `null`, it indicates that the initiation
   * state is unknown.
   * @param val - The initiation state to set.
   * `true` if installing libraries has been initiated, `false` if not, or `null`
   * if the state is unknown.
   */
  const setInstallLibrariesInitiated = (val: boolean | null) => {
    installLibrariesInitiated.value = val
  }
  /**
   * Sets the initiation state of starting pods.
   *
   * This function updates the `startPodsInitiated` reactive value with the
   * provided state. If the value is `null`, it indicates that the initiation
   * state is unknown.
   * @param val - The initiation state to set.
   *   `true` if starting pods has been initiated, `false` if not, or `null`
   *   if the state is unknown.
   */
  const setStartPodsInitiated = (val: boolean | null) => {
    startPodsInitiated.value = val
  }
  /**
   * Sets the initiation state of stopping pods.
   *
   * This function updates the `stopPodsInitiated` reactive value with the
   * provided state. If the value is `null`, it indicates that the initiation
   * state is unknown.
   * @param val - The initiation state to set.
   * `true` if stopping pods has been initiated, `false` if not, or `null`
   * if the state is unknown.
   */
  const setStopPodsInitiated = (val: boolean | null) => {
    stopPodsInitiated.value = val
  }
  /**
   * Sets the loading state for pods.
   *
   * This function updates the `loadingPods` reactive value to indicate
   * whether pods are currently loading.
   * @param val - The loading state to set.
   * `true` if pods are loading, `false` if not.
   */
  const setLoadingPods = (val: boolean) => {
    loadingPods.value = val
  }
  /**
   * Sets the value of `initNextAction`.
   * @param val - The new value to set for `initNextAction`.
   */
  const setInitNextAction = (val: INIT_NEXT_ACTION) => {
    initNextAction.value = val
  }

  return {
    isPodInitializationCompleted,
    setIsPodInitializationCompleted,
    logMessages,
    setLogMessages,
    setStartLoggingMessages,
    startLoggingMessages,
    serverErrorsMessages,
    setServerErrorMessages,
    socketConnected,
    setSocketConnected,
    setSocketCompleted,
    isPodInitializationInProgress,
    setIsPodInitializationInProgress,
    subDomainUrl,
    setSubDomainUrl,
    isPodInitializationHasErrors,
    setIsPodInitializationHasErrors,
    sessionId,
    setSessionId,
    kurukkuKuriId,
    setkurukkuKuriId,
    projectKeyAdvCompiler,
    setProjectKeyAdvCompiler,
    projectIdAdvCompiler,
    setProjectIdAdvCompiler,
    currentWsServiceAdvCompiler,
    setCurrentWsServiceAdvCompiler,
    startPodsCompleted,
    setStartPodsCompleted,
    stopPodsCompleted,
    setStopPodsCompleted,
    installLibrariesCompleted,
    setInstallLibrariesCompleted,
    installLibrariesInitiated,
    setInstallLibrariesInitiated,
    startPodsInitiated,
    setStartPodsInitiated,
    stopPodsInitiated,
    setStopPodsInitiated,
    loadingPods,
    setLoadingPods,
    socketInProgress,
    socketLogsInProgress,
    initNextAction,
    setInitNextAction
  }
})
