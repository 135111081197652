export enum WS_PROGRESS {
  COMPLETE = 'COMPLETE',
  ON_PROGRESS = 'ON_PROGRESS'
}

export enum WS_STATUS_CODE {
  BAD_REQUEST = 'BAD_REQUEST',
  OK = 'OK'
}

export enum WS_ERROR_CODES {
  ROBOT_CHECK_INVALID = 'ROBOT_CHECK_INVALID',
  INVALID_PROJECT_KEY = 'INVALID_PROJECT_KEY',
  ONE_MAXIMUM_BOX_LIMIT = 'ONE_MAXIMUM_BOX_LIMIT',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  CREATE_POD_TIMEOUT = 'CREATE_POD_TIMEOUT',
  INVALID_DESTINATION = 'INVALID_DESTINATION',
  POD_READY = 'POD_READY',
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  POD_NOT_READY = 'POD_NOT_READY',
  RUNBOX_NOT_FOUND = 'RUNBOX_NOT_FOUND',
  INVALID_PROJECT_ID = 'INVALID_PROJECT_ID',
  POD_CREATION_ONGOING = 'POD_CREATION_ONGOING'
}

export enum INIT_NEXT_ACTION {
  READY = 'READY',
  INIT_POD = 'INIT_POD',
  RE_INIT_IDE = 'RE_INIT_IDE',
  REDIRECT = 'REDIRECT',
  KILL = 'KILL',
  WAIT = 'WAIT'
}

export enum CURRENT_RUNNING_WS_SERVICE {
  INIT = 'init',
  START = 'start',
  STOP = 'stop',
  INSTALL_LIBRARY = 'install'
}
