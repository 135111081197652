export interface ICredit {
  quota: number
  used: number
}

export interface IGenarateText {
  text: string
  prompt: string
}

export const AICONSTANT = {
  CHAT: 'ide_chat',
  CHATJDROID: 'chat_jdroid'
}

export enum AIType {
  CHAT = 'ide_chat',
  CHATJDROID = 'chat',
  OPTIMIZE = 'optimize',
  DEBUG = 'debug',
  EXPLAIN = 'explain',
  INLINECOMMENTS = 'comment',
  EXPLAINERROR = 'explain_error',
  GENERATETIITLE = 'generate_title'
}
export interface IAIType {
  type: AIType
  header: string
  description: string
  selectionRequired: boolean
  ideWelcomeId: string
  ideChatId: string
}

export enum ChatStatus {
  STREAMING = 'streaming',
  LOADING = 'loading',
  SUCCESS = 'success',
  CANCLED = 'cancled',
  ERROR = 'error',
  NOCREDIT = 'no-credit',
  NOROBOTCHECK = 'no-robot-check'
}
export interface IChat {
  type: AIType
  autoReplaceEditor?: boolean
  ideContent?: string | null
  outputContent?: string | null
  prompt?: string
  header?: string
  status: ChatStatus
  response: string | null
  eventInjected?: boolean
  elementIDList?: string[]
  credit?: ICredit | null
  aiPayload?: string | null
}

export interface ISetHistory {
  aiPayload: string
  answer: string
}

export interface IAINotification {
  type: AIType
  buttonText: string
  message: string
  id: string
}

export const genarateTextList: IGenarateText[] = [
  {
    text: 'Create a Tic Tac Toe Game',
    prompt: 'Create a Tic Tac Toe Game'
  },
  {
    text: 'Sort an array of numbers in ascending order',
    prompt: 'Sort an array of numbers in ascending order'
  },
  {
    text: 'REST API to handle GET and POST Requests',
    prompt: 'REST API to handle GET and POST Requests'
  },
  {
    text: 'Fibonacci Sequence',
    prompt: 'write a function that returns the nth number in the Fibonacci sequence'
  },
  {
    text: 'String Reversal (change Hello World to dlroW olleH)',
    prompt: 'String Reversal (change Hello World to dlroW olleH)'
  }
]

export const AITypeList: IAIType[] = [
  {
    type: AIType.OPTIMIZE,
    header: 'Optimize code',
    description: ' Modify code for better performance.',
    selectionRequired: true,
    ideWelcomeId: 'optimize-code-ide',
    ideChatId: 'optimize-code-link-ide'
  },
  {
    type: AIType.DEBUG,
    header: 'Debug code',
    description: 'Fix error and bug in your code.',
    selectionRequired: true,
    ideWelcomeId: 'debug-code-ide',
    ideChatId: 'debug-code-link-ide'
  },
  {
    type: AIType.EXPLAIN,
    header: 'Explain code',
    description: 'Get detailed explanation of how your code works.',
    selectionRequired: true,
    ideWelcomeId: 'explain-code-ide',
    ideChatId: 'explain-code-link-ide'
  },
  {
    type: AIType.INLINECOMMENTS,
    header: 'Inline comments',
    description: 'Improve code readability by adding comments.',
    selectionRequired: true,
    ideWelcomeId: 'inline-comments-ide',
    ideChatId: 'inline-comments-link-ide'
  }
]

export const AINotificationKeyCount = 100
export const AINotificationTimeOut = 10000
export const AINotificationStopTimeOut = 1800000 // 30 minutes

export const AINotificationList: IAINotification[] = [
  {
    type: AIType.OPTIMIZE,
    buttonText: 'Optimize code',
    message: "Want me to improve your code's performance?",
    id: 'optimize-code-popup-ide'
  },
  {
    type: AIType.DEBUG,
    buttonText: 'Debug code',
    message: 'Want me to debug your code?',
    id: 'debug-code-popup-ide'
  },
  {
    type: AIType.EXPLAIN,
    buttonText: 'Explain code',
    message: 'Want me to explain code logic?',
    id: 'explain-code-popup-ide'
  },
  {
    type: AIType.INLINECOMMENTS,
    buttonText: 'Add inline comments',
    message: 'Want me to improve code readability by adding comments?',
    id: 'inline-comments-popup-ide'
  }
]

export const AICHAT_TYPE = {
  DEFAULT: 'DEFAULT',
  PROJECT: 'PROJECT'
}

export const AISampleQuestions: string[] = [
  'Generate code for simple calculator',
  'Explain the logic of flappy bird game',
  'Optimize a bubble sort algorithm'
]

export const AICHAT_MODE = {
  QA: 'qa',
  CHAT: 'chat'
}

export const CHECKCANCELCHATERRORVAL = 'DOMException: BodyStreamBuffer was aborted'
